import React from "react"
import { graphql } from "gatsby"
import CustomLayout from '../layout/default'
import { Container, Row, Col } from '../../../shared-ui/components/ui/wrapper'
import SectionTitle from '../../../shared-ui/components/ui/section-title'
import ServiceBox from '../../../shared-ui/components/box-large-image/layout-two'
import Blog from '../../../shared-ui/components/blog/layout-one'
import Heading from '../../../shared-ui/components/ui/heading'
import Text from '../../../shared-ui/components/ui/text'
import Button from '../../../shared-ui/components/ui/button'
import HeroSection from '../components/hero-section'
import CTASection from '../components/cta-section'
import ContactFormSection from '../components/contact-form-section'
import TestimonialsSection from '../components/testimonials-section'
import styled from 'styled-components'
import {device} from '../../../shared-ui/theme'

const ContactUsPage = ({location, data, props }) => {  
 
  return (    
    <CustomLayout location={location}>           
      <ContactFormSection />  
    </CustomLayout>)
}

ContactUsPage.defaultProps = {
    aboutSectionTitleStyle: {
        mb: '60px',
        responsive: {
            small: {
                mb: '47px'
            }
        }
    },
    aboutHeadingStyle: {
        layout: 'highlight',
        mr: '48px'
    },
    aboutSubheadingStyle: {
        as: 'h6',
        mt: '30px'
    },
    aboutDescStyle: {
        fontSize: '18px',
        mb: '20px'
    },
    aboutBtnStyle: {
        varient: 'texted',
        icondistance: '4px'
    }
}
 
export default ContactUsPage